/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location }) => {

  if (location.pathname === "/") {
    const SVG_IMAGE_DESC = `
      <svg class="bg_top" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 951 1235">
        <g stroke="#0083e5" stroke-width="3">
          <path id="lamp-path" d="M168 652c0 7.29-4.81 12.7-10.2 12.7s-10.1-5.38-10.1-12.7c0-7.29 4.8-12.7 10.1-12.7S168 644.68 168 652z"/>
          <path id="lamp-path2" d="M212 650c0 8.38-5.54 14.7-11.8 14.7s-11.8-6.27-11.8-14.7c0-8.38 5.54-14.7 11.8-14.7S212 641.57 212 650z"/>
          <path id="lamp-path3" d="M266 648c0 9.29-6.14 16.3-13.1 16.3-6.99 0-13.1-7.01-13.1-16.3s6.14-16.3 13.1-16.3c6.99 0 13.1 7.01 13.1 16.3z"/>
          <path id="top-path" d="M468 .075s13 303-103 364-331 29.2-323-30.3c8.74-64.5 411 7.46 531 44.8s164 55.3 235 102c32.9 21.6 88.1 68.7 118 120s33.7 108 .24 127c-78 45.1-86.6-114-424-91-65.4 4.48-132 15.3-190 29.8l-.005-74.3c0-9.56-8.32-17-17.8-15.9l-177 20.2a8 8 0 0 0-7.09 7.95v95.3c0 4.12 3.12 7.56 7.22 7.96l137 13.5c11.8 0 19.7 34.7-12.6 53-13.9 7.9 43.4-4.16 58.7-41.7 15.3-37.6-30.4 30.4-111 28.6 16-10.2 22.2-17.6 25.8-31.4.589-2.29-1.06-4.52-3.41-4.76l-102-10.4a7.99 7.99 0 0 1-7.19-7.95v-63.1c0-14.4-98.3-10.6-102 58.2s114 85.4 176 64.1c-53.4 23.5-126 23.6-155-4.72-12.8-12.6 8.17 30.7 33.6 48.8-48 99.7-27 187 92.8 225 120 38.5 316 77.8 313 200"/>
        </g>
      </svg>
    `;

    const advantageBlockEl = document.querySelector(".advantage-one");
    if (document.documentElement.scrollWidth > 768) {
      advantageBlockEl.insertAdjacentHTML('afterbegin', SVG_IMAGE_DESC);
    }

    let paths = [];

    if (window.screen.width <= 768) {
      if (document.getElementById("top_mobile")) {
        paths.push({
          element: document.getElementById("top_mobile"),
          k: 12,
        });
      }

      if (document.getElementById("bottom-path-mobile")) {
        paths.push({
          element: document.getElementById("bottom-path-mobile"),
          k: 12,
        });
      }

      if (document.getElementById("range")) {
        paths.push({
          element: document.getElementById("range"),
          k: 10,
        });
      }

      if (document.getElementById("range2")) {
        paths.push({
          element: document.getElementById("range2"),
          k: 10,
        });
      }

      if (document.getElementById("range3")) {
        paths.push({
          element: document.getElementById("range3"),
          k: 10,
        });
      }

      if (document.getElementById("procent")) {
          paths.push({
          element: document.getElementById("procent"),
          k: 8,
          });
      }

      if (document.getElementById("hand")) {
          paths.push({
          element: document.getElementById("hand"),
          k: 8,
          });
      }

      if (document.getElementById("card")) {
          paths.push({
          element: document.getElementById("card"),
          k: 7,
          });
      }

      if (document.getElementById("capital")) {
          paths.push({
          element: document.getElementById("capital"),
          k: 8,
          });
      }

      if (document.getElementById("svg-line-scales")) {
        paths.push({
          element: document.getElementById("svg-line-scales"),
          k: 10,
        });
      }

      if (document.getElementById("contact")) {
          paths.push({
            element: document.getElementById("contact"),
            k: 9,
          });
      }
    } else {
      if (document.getElementById("top-path")) {
        paths.push({
          element: document.getElementById("top-path"),
          k: 6,
        });
      }

      if (document.getElementById("lamp-path")) {
        paths.push({
          element: document.getElementById("lamp-path"),
          k: 10,
        });
      }

      if (document.getElementById("lamp-path2")) {
        paths.push({
          element: document.getElementById("lamp-path2"),
          k: 10,
        });
      }

      if (document.getElementById("lamp-path3")) {
        paths.push({
          element: document.getElementById("lamp-path3"),
          k: 10,
        });
      }

      if (document.getElementById("bottom_path")) {
        paths.push({
          element: document.getElementById("bottom_path"),
          k: 1.5,
        });
      }
    }

    if (paths.length) {
      paths.forEach(path => {
        const { element, k } = path;
        let pathLength, visiblePathLength, scrollPosition;
        let position = element.id === "top-path" || element.id === "top_mobile" || element.id === "range" || element.id === "range2" || element.id === "range3" ?
          0 :
          window.pageYOffset - (document.documentElement.clientHeight - element.getBoundingClientRect().top);

        pathLength = element.getTotalLength();

        element.style.strokeDasharray = pathLength;
        element.style.strokeDashoffset = pathLength;

        window.addEventListener("scroll", function () {
          scrollPosition =
            (document.documentElement.scrollTop - position + document.body.scrollTop) /
            (document.documentElement.scrollHeight - document.documentElement.clientHeight);
          visiblePathLength = pathLength * scrollPosition * k;

          if (window.pageYOffset >= position) {
            if (element.id === "bottom-path-mobile") {
              element.style.strokeDashoffset = pathLength + visiblePathLength;
              if (element.style.strokeDashoffset < pathLength) {
                element.style.strokeDashoffset = pathLength;
              } else if (element.style.strokeDashoffset > pathLength * 2) {
                element.style.strokeDashoffset = 0;
              }
            } else {
              element.style.strokeDashoffset = pathLength - visiblePathLength;
              if (element.style.strokeDashoffset > pathLength) {
                element.style.strokeDashoffset = pathLength;
              } else if (element.style.strokeDashoffset < 0) {
                element.style.strokeDashoffset = 0;
              }
            }
          }
        });
      })
    }
  }
}
