const initialState = {
  openModal: false,
  settings: {
    form: {
      showHeader: true,
    },
    info: {
      showHeader: false,
    },
  },
};

export const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_MODALS_STATE": {
      const { modalName } = action.data;

      return {
        ...state,
        openModal: modalName,
      };
    }
    default: {
      return state;
    }
  }
};
