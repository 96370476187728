import { createStore, combineReducers } from "redux";
import { userReducer } from "./reducers/user";
import { modalsReducer } from "./reducers/modals";

const rootReducer = combineReducers({
  user: userReducer,
  modals: modalsReducer,
});

const composeEnhancers =
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__();

export default preloadedState => {
  return process.env.NODE_ENV === "development"
    ? createStore(rootReducer, preloadedState, composeEnhancers)
    : createStore(rootReducer, preloadedState);
};
